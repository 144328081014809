//____ FIXED HEADER ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function nl_fn_scroll_to() {
    var window_top = $(window).scrollTop();
    var div_top = $("#page").offset().top + 120;
    if (window_top > div_top) {
        $('.header').addClass('fixed');
    } else {
        $('.header').removeClass('fixed');
    }
}