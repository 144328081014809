//____ HEADER - RESPONSIVE MENU ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function nl_fn_responsive_menu() {
    $(document).on('click', '#main-header #logo', function (e) {
        if ($(this).find(".icon-menu").css("display") == "inline-block") {
            e.preventDefault();
            $("body").toggleClass("no-scroll");
            $("#bg").fadeToggle(300);
            $(this).parents("#main-header").find(".header-nav").fadeToggle(300).toggleClass("toggle");
        }
    });
    $(document).on('click', '#main-header .icon-close, #bg', function () {
        $("body").toggleClass("no-scroll");
        $("#bg").fadeToggle(300);
        $("#main-header").find(".header-nav").fadeToggle(300).toggleClass("toggle");
    });
}