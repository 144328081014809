//____ MORE CONTENT / TEXT LOADING ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK, NOTES: very experimental ____ //
function nl_fn_more_content() {
    $(".content-more").each(function () {
        var height = $(this).prev(".content-inner").css("max-height").replace(/[^-\d\.]/g, '');
        var text_length = $(this).prev(".content-inner").find(".content").height();
        if (text_length <= height) {
            $(this).hide();
        } else {
            $(this).show();
        }
        $(this).on("click", function (e) {
            e.preventDefault();
            $(this).find(".more-title").toggleClass("hide");
            $(this).find(".icon").toggleClass("icon-chevron-thin-down").toggleClass("icon-chevron-thin-up");
            if (!$(this).prev(".content-inner").hasClass("active")) {
                $(this).prev(".content-inner").toggleClass("active").css("max-height", text_length + 20 + "px");
            } else {
                $(this).prev(".content-inner").toggleClass("active").attr("style", "");
            }
        });
    });
}