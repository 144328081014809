//____ COOKIE WINDOW POP-UP ____ //
//____ AUTHOR: ADAM PLATKEVIČ & MICHAL SOBČÁK ____ //
function nl_fn_cookie() {
    var $cookies = $('.cookies');
    if(!$cookies.length) {
        return;
    }
    $cookies.find('.cookies__confirm').on('click', function() {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        document.cookie = 'cookies-confirmation=1; path=/; expires=' + date.toGMTString();
        $cookies.slideUp();
    });
}