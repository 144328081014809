//____ TABS SWITCH CONTENT ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function nl_fn_switch_content(nl_fn_switch_class,slide) {
    $(".switch-content").hide();
    $(".switch-content.active").show();
    var button = $(nl_fn_switch_class).find(".switch-nav").find(".switch-button");
    var content = $(nl_fn_switch_class).find(".switch-area").find(".switch-content");
    content.each(function(n) {
        var button_class = ("item-" + n);
        $(this).addClass(button_class);
    });
    button.each(function(n) {
        var button_class = ("item-" + n);
        $(this).addClass(button_class);
        $(this).on("click", function (e) {
            e.preventDefault();
            if (!$(this).hasClass("active")) {
                $(this).closest(".switch-nav").find(".switch-button").removeClass("active");
                $(this).addClass("active");
                if (slide == 1) {
                    $(this).parents(nl_fn_switch_class).find(".switch-area").find(".switch-content").stop(true).slideUp(500).removeClass("active");
                    $(this).parents(nl_fn_switch_class).find(".switch-area").find("."+ button_class).delay(500).slideDown(500).addClass("active");
                } else {
                    $(this).parents(nl_fn_switch_class).find(".switch-area").find(".switch-content").hide().css("opacity","0").removeClass("active");
                    $(this).parents(nl_fn_switch_class).find(".switch-area").find("."+ button_class).show().css("opacity","1").addClass("active");
                }
                $('.switch-content .slick-slider').each(function(){
                    $(this).slick("reinit");
                });
            }
        });
    });
}