function fn_visual() {
    var visual = $("#hp-visual");
    var visual_inner = $('.hp-visual-inner');
    if (visual_inner.length) {
        if ($(window).width() > 480) {
            visual_inner.slick({
                lazyLoad: 'progressive',
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 9000,
                fade: true,
                cssEase: 'linear',
                arrows: false,
                dots: false,
                pauseOnHover: false
            });
            visual_inner.on('beforeChange', function(){
                $(this).find(".slick-active").next().find("img").addClass("lazyload");
            });
        }

    }
    visual.find(".content-explore a").on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: visual.next("*").offset().top - $('.header').height()
        }, 700);
    });
}
