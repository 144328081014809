//____ CHECK IF MENU IS ACTIVE ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function nl_fn_check_size() {
    $(window).resize(function() {
        if ($("#main-header").find(".icon-menu").css("display") == "none") {
            $(".header-nav").css("display","inline-block").removeClass('toggle');
            $("#bg").fadeOut(300);
            $("body").removeClass("no-scroll");
        } else {
            if ($("#bg").css("display") == "none") {
                $(".header-nav").hide();
            }
        }
    });
}