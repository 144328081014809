//--- MAIN --- //
$(function () {
    var reInitGCaptcha;

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LfXENMUAAAAACsqRQQdxDwOxY-sIv-Mre5Dizle").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LfXENMUAAAAACsqRQQdxDwOxY-sIv-Mre5Dizle', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }

    $(".header-nav").parent().prepend('<button id="bg"></button>');

    nl_fn_responsive_menu();

    nl_fn_langs_menu();

    nl_fn_hover_touch_unstick();

    nl_fn_cookie();

    nl_fn_check_size();

    nl_fn_footer();

    $('.parallax').parallax();

    $('.product-gallery').each(function () {
        var gallery_data = $(this).data('gallery');
        $(this).on('click', function (e) {
            e.preventDefault();
            $(this).lightGallery({
                dynamic: true,
                dynamicEl: gallery_data
            })
        });
    });

    setTimeout(function () {
        nl_fn_anchor_link();
    }, 1);
});

// --- COMPONENTS --- //
$(function () {
    fn_visual();
});

// --- AFTER PAGE LOAD --- //
$(window).load(function () {
});