//____ ANCHOR LINKS ANIMATIONS ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function nl_fn_anchor_link() {
    var $anchor_link = $("[data-anchor]"),
        $anchor_elm = $("[data-anchor-elm]"),
        $hash = window.location.hash;
    $anchor_link.on("click", function (e) {
        var $id = $(this).attr("href"),
            $anchor_elm = $("[data-anchor-elm="+$id.replace('#','')+"]");

        if ($anchor_elm.length) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $anchor_elm.offset().top - ($('.header').height())
            }, 500);
            if($(this).data("anchor") !== "none") {
                window.location.hash = $id;
            }
        }
    });

    $anchor_elm.each(function(){
        if($hash && $(this).data("anchor-elm") == $hash.replace('#','')) {
            var $anchor_elm = $("[data-anchor-elm="+$hash.replace('#','')+"]");

            var $offset_data = $anchor_elm.data("anchor-offset");
            var $offset_data_2 = $anchor_elm.data("anchor-offset-2");

            var $offset;
            if ($(window).width() < 1170 && $offset_data_2) {
                $offset = $offset_data_2 ? $offset_data_2:0;
            } else {
                $offset = $offset_data ? $offset_data:0;
            }


            $('html, body').animate({
                scrollTop: $anchor_elm.offset().top - ($('.header').height())
            }, 0);
        }
    });
}